<template>
  <main>
    <v-card flat>
      <v-card-title class="title-block">
        <div>
          Assets List

          <v-btn icon class="ml-3" @click="getAssets"  :disabled="!isAuthenticated || assets.loading"><v-icon>{{ icons.mdiReload }}</v-icon></v-btn>
        </div>

        <v-btn
          color="primary"
          :loading="loading"
          @click="createAsset"
          :disabled="!isAuthenticated || loading"
        >Create Asset</v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="tableColumns"
          disabled
          :footer-props="{
            'items-per-page-options': [10, 10],
            'disable-items-per-page': true,
            'disable-pagination': assets.loading
          }"
          :items="assets.list"
          :options.sync="assets.options"
          :page="assets.meta.page"
          :server-items-length="assets.meta.total || 0"
          :loading="assets.loading"
          @pagination="changePagination($event)"
          :no-data-text="'No data available'"
          :loading-text="'Loading, pls wait'"
          class="text-no-wrap"
        >
          <template #[`item.createdAt`]="{item}">
            {{ formatDate(item.createdAt) }}
          </template>

          <template #[`item.status`]="{item}">
            <span>{{item.status}}</span>
            <v-tooltip
              bottom color="error"
              v-if="item.review && item.review.message && item.status === 'review-failed'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="error"
                  class="ml-2"
                  dark icon small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>{{icons.mdiInformation}}</v-icon>
                </v-btn>
              </template>
              <span>{{item.review.message}}p</span>
            </v-tooltip>
          </template>

          <template #[`item.category`]="{item}">
            {{ getCategoryAlias(item) }}
          </template>

          <template #[`item.type`]="{item}">
            {{ getTypeAlias(item) }}
          </template>

          <template #[`item.actions`]="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link dense @click="editAsset(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiFileEdit }}</v-icon>
                    <span>Edit Asset</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link dense @click="transferAsset(item)" v-if="userdata.isAdmin">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiSwapHorizontal }}</v-icon>
                    <span>Transfer</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link dense
                  @click="submitForReview(item)"
                  v-if="
                    ['nft'].includes(item.category) &&
                    ['draft', 'review-failed'].includes(item.status) &&
                    item.type === 'custom'
                  "
                >
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiBugCheckOutline }}</v-icon>
                    <span>Submit for Review</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link dense
                  :href="'https://launchpad.nftiz.com/' + (item.key || item.contract.address)"
                  target="_blank"
                  v-if="['live'].includes(item.status)"
                >
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiShieldLinkVariantOutline }}</v-icon>
                    <span>Visit Page</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link dense
                  :to="`/advertiser/launchpad/assets-report?assetId=${item._id}`"
                  v-if="['live'].includes(item.status)"
                >
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiGoogleAnalytics }}</v-icon>
                    <span>Asset Report</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link dense
                  @click="deployAsset(item)"
                  v-if="
                    ['nft'].includes(item.category) &&
                    ['draft'].includes(item.status) &&
                    item.type === 'internal'
                  "
                >
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiFlash }}</v-icon>
                    <span>Deploy</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link dense
                  @click="launchAsset(item)"
                  v-if="['whitelist'].includes(item.category) &&  ['draft'].includes(item.status)"
                >
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiFlash }}</v-icon>
                    <span>Launch</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link dense
                  @click="removeAsset(item)"
                >
                  <v-list-item-title>
                    <v-icon size="18" class="me-2" color="error">{{ icons.mdiDeleteOutline }}</v-icon>
                    <span class="error--text">Remove</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import {
  mdiReload,
  mdiFileEdit,
  mdiDotsVertical,
  mdiBugCheckOutline,
  mdiFlash,
  mdiInformation,
  mdiDeleteOutline,
  mdiSwapHorizontal,
  mdiShieldLinkVariantOutline,
  mdiGoogleAnalytics,
} from '@mdi/js'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'

import bytecode from '@/assets/bytecode.js'
import { getGasPriceForNetwork } from '@/utils/web3'
import { eventBus } from '@/utils/eventBus'

export default {
  data: () => ({
    icons: {
      mdiReload,
      mdiFileEdit,
      mdiDotsVertical,
      mdiBugCheckOutline,
      mdiFlash,
      mdiInformation,
      mdiDeleteOutline,
      mdiSwapHorizontal,
      mdiShieldLinkVariantOutline,
      mdiGoogleAnalytics,
    },
    loading: false,
    deploy: {
      loading: false,
    },
    offers: [],
    assets: {
      loading: false,
      docs: [],
      meta: {},
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
    },
  }),
  computed: {
    ...mapGetters('web3auth', ['isAuthenticated', 'userdata']),
    ...mapState({
      web3: state => state.web3auth.web3,
      web3config: state => state.app.web3,
    }),
    tableColumns() {
      return [
        { text: 'ASSET', value: 'name', sortable: false },
        { text: 'CATEGORY', value: 'category', sortable: false },
        { text: 'TYPE', value: 'type', sortable: false },
        { text: 'STATUS', value: 'status', sortable: false },
        { text: 'CONTRACT', value: 'contract.address', sortable: false },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ]
    },
  },
  created() {
    this.getOffers()

    eventBus.$on('asset-builder-update', () => {
      this.getAssets({ page: 1 })
    })

    eventBus.$on('asset-transfer-update', () => {
      this.getAssets({ page: 1 })
    })
  },
  methods: {
    getAssets(query) {
      if (!this.isAuthenticated) return

      this.assets.loading = true

      const params = {
        limit: this.assets.options.itemsPerPage,
        query: JSON.stringify(query),
        sort: { createdAt: 'desc' },
        ...params,
      }

      this.$http
        .get('/asset/user', { params })
        .then(({ data }) => {
          const { meta, docs } = data

          this.assets.meta = meta
          this.assets.list = docs
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.assets.loading = false
          })
        })
    },

    getOffers() {
      this.$http
        .get('offer/user/all')
        .then(({ data }) => {
          this.offers = data.offers
        })
        .catch(() => {})
    },

    submitForReview(item) {
      if (this.loading) return

      this.loading = true

      this.$http
        .put(`/asset/${item._id}/review`)
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'success',
                message: `Asset successfully saved!`,
              },
              { root: true },
            )
          }

          this.getAssets({ page: 1 })
        })
        .catch(err => {
          if (err.response.data) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'error',
                message: err.response.data.message || 'Unknown error!',
              },
              { root: true },
            )
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    changePagination(pagination) {
      this.getAssets({
        page: pagination.page,
      })
    },

    getCategoryAlias(item) {
      const aliases = {
        nft: 'NFT Collection',
        whitelist: 'Whitelist',
        token: 'Token',
      }

      return aliases[item.category]
    },

    getTypeAlias(item) {
      const aliases = {
        custom: 'Custom Contract',
        internal: 'Internal Contract (NFTIZ)',
      }

      return aliases[item.type]
    },

    showBuilderModal(data) {
      this.$store.commit('modal/SET_OPTIONS', {
        show: true,
        type: 'asset-builder',
        modalAttrs: {
          persistent: true,
          'max-width': 1200,
        },
        componentAttrs: {
          offers: this.offers,
          assetData: data,
        },
      })
    },

    createAsset() {
      this.showBuilderModal(null)
    },

    editAsset(item) {
      this.showBuilderModal(JSON.parse(JSON.stringify(item)))
    },

    transferAsset(item) {
      this.$store.commit('modal/SET_OPTIONS', {
        show: true,
        type: 'asset-transfer',
        modalAttrs: {
          persistent: true,
          'max-width': 800,
        },
        componentAttrs: {
          assetData: JSON.parse(JSON.stringify(item)),
        },
      })
    },

    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },

    showDeployErrorMessage(message = null, stop) {
      this.$store.dispatch(
        'notification/GENERATE_NOTIFICATION',
        {
          type: 'error',
          message: 'Unable to perform this operation! ' + message,
        },
        { root: true },
      )

      if (stop) {
        this.deploy.loading = false
      }
    },

    async deployAsset(item) {
      if (this.deploy.loading || !['draft'].includes(item.status) || item.type !== 'internal') return

      this.deploy.loading = true

      const { networks, abi } = this.web3config
      const provider = networks[item.blockchain] && networks[item.blockchain].provider

      if (!provider) {
        return this.showActionErrorMessage('[Deploy]', true)
      }

      const validNetwork = await this.$store.dispatch('web3auth/SWITCH_NETWORK', networks[item.blockchain].chainId)

      if (!validNetwork) {
        return (this.deploy.loading = false)
      }

      const contractAbi = abi['NFTMinter']
      const contractBytecode = bytecode['NFTMinter']

      if (!contractAbi || !contractBytecode) {
        return this.showActionErrorMessage('[Deploy]', true)
      }

      const signer = this.web3.eth.accounts.create(this.web3.utils.randomHex(32))
      const NFTMinter = new this.web3.eth.Contract(contractAbi)

      const gasPrice = await getGasPriceForNetwork(item.blockchain)

      const { baseTokenURI, nftLimit, name, symbol } = item.contract

      this.$store.commit(
        'app/TOGGLE_TRANSACTION_LOADER',
        {
          show: true,
          message: 'Wait for the transaction to complete',
        },
        { root: true },
      )

      await NFTMinter.deploy({
        arguments: [baseTokenURI, nftLimit, name, symbol, signer.address],
        data: contractBytecode,
      })
        .send({ from: this.userdata.wallet, gasPrice })
        .then(async res => {
          await this.saveAssetContractData(item._id, {
            address: res._address,
            signerPrivateKey: signer.privateKey,
            abi: JSON.stringify(contractAbi),
          })
        })
        .catch(err => {
          console.log(err)

          this.$store.dispatch(
            'notification/GENERATE_NOTIFICATION',
            {
              type: 'error',
              message: `There was an error when performing a transaction.`,
            },
            { root: true },
          )
        })
        .finally(() => {
          this.$store.commit('app/TOGGLE_TRANSACTION_LOADER', { show: false }, { root: true })

          return (this.deploy.loading = false)
        })
    },

    async saveAssetContractData(assetId, data) {
      return this.$http.put(`/asset/${assetId}/deploy`, data).then(res => {
        if (res.data.success) {
          this.$store.dispatch(
            'notification/GENERATE_NOTIFICATION',
            {
              type: 'success',
              message: `Asset successfully deployed!`,
            },
            { root: true },
          )

          this.getAssets({ page: 1 })
        }
      })
    },

    async launchAsset(item) {
      if (this.loading) return

      this.loading = true

      this.$http
        .put(`/asset/${item._id}/launch`)
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'success',
                message: `Asset successfully launched!`,
              },
              { root: true },
            )
          }

          this.getAssets({ page: 1 })
        })
        .catch(err => {
          if (err.response.data) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'error',
                message: err.response.data.message || 'Unknown error!',
              },
              { root: true },
            )
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    async removeAsset(item) {
      if (this.loading) return

      this.loading = true

      this.$http
        .delete(`/asset/${item._id}`)
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'success',
                message: `Asset successfully removed!`,
              },
              { root: true },
            )
          }

          this.getAssets({ page: 1 })
        })
        .catch(err => {
          if (err.response.data) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'error',
                message: err.response.data.message || 'Unknown error!',
              },
              { root: true },
            )
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.title-block {
  display: flex;
  justify-content: space-between;
}

.deploy-loader {
  height: 150px;
}

@media screen and (max-width: 600px) {
  .title-block {
    flex-direction: column;
    align-items: center;
  }
}
</style>
